import React from "react"
import Layout from "../layouts/layout/layout"
import HeaderSmall from "../components/header-small/header-small"
import FeatureList from "../components/feature-list/feature-list"
import DefaultLayout from "../layouts/default-layout"
import DefaultTextBlock from "../components/default-text-block/default-text-block"
import ContactForm from "../components/contact-form/contact-form"
import { Helmet } from "react-helmet"
import HeaderImage from "../../static/media/collage.png"

const items = [
  { text: "Patrick Landuyt", key: 1, icon: "fas fa-id-card" },
  { text: "0477 624 790", key: 2, icon: "fas fa-phone-square-alt" },
  { html: <a href="mailto:info@vastgoed-aankoopbegeleiding.be" className="link">info@vastgoed-aankoopbegeleiding.be</a>, key: 3, icon: "far fa-envelope" },
  { text: "Nazarethstraat 6, 9840 De Pinte", key: 4, icon: "fas fa-map-marker" },
  { text: "Werkgebied: heel Vlaanderen en Brussel", key: 5, icon: "fas fa-map" },
  { html: <div>Maakt deel uit van de groep <a href="https://www.immophone-partners.be" target="blank" className="link">www.immophone-partners.be</a></div>, icon: "fas fa-building", key: 6}
]

export default () => (
  <Layout description="Dank voor uw bezoekje aan onze website!">
    <Helmet>
      <title>Contact vastgoed aankoopbegeleding</title>
      <meta name="description" content="Wij geven tips bij het aankopen van een pand of huis. Get in touch!"></meta>
      <meta name="keywords" context="contact, vastgoed, aankoop, begeleiding, aakoopbegeleiding, huis, pand, immo, immophone, gent, De Pinte"></meta>          
    </Helmet>
    <HeaderSmall title="Hoe kunnen we u helpen?" imageUrl={HeaderImage}></HeaderSmall>
    <DefaultLayout>
      <p className="default-text"><span>Hi! &#9995; <br></br> Meer info of een offerte aanvragen?<br></br> Het kan via onderstaand formulier, telefonisch of via mail.</span></p>
      {/* <CenteredContentBlock content={<span>Hi! &#9995; <br></br> Meer info of een offerte aanvragen? Het kan via onderstaand formulier, telefonisch of via mail.</span>}></CenteredContentBlock> */}
      <div className="contact details">
        <DefaultTextBlock
          title="Contact formulier"
          text={<ContactForm></ContactForm>}
        ></DefaultTextBlock>
        <FeatureList items={items} title="Contactgegevens"></FeatureList>
      </div>
    </DefaultLayout>
  </Layout>
)
