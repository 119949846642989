import React from "react"
import "./contact-form.css"

export default class ContactForm extends React.Component {
  render() {
    return (
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="contact-form"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="form-group">
          <label for="name">Naam</label>
          <input id="name" type="text" name="name" required/>
        </div>
        <div className="form-group">
          <label for="email">Email</label>
          <input id="email" type="email" name="email" required/>
        </div>
        <div className="form-group">
          <label for="phone">Telefoon <span>optioneel</span></label>
          <input id="phone" type="phone" name="phone" />
        </div>
        <div className="form-group">
          <label for="message">Bericht</label>
          <textarea id="message" rows="5" name="message" required></textarea>
        </div>
        <button type="submit">send</button>
      </form>
    )
  }
}
